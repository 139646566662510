//@author: devin
import { ModalDialogComponent } from "./modals/modal.dialog.component";
import { ModalImageUploadComponent } from "./modals/modal.image.upload.component";
import { ModalRecommendedBrowsersComponent } from "./modals/modal.recommended.browsers.component";
import { ModalCancelRequestComponent } from "./modals/modal.cancel.request.component";
import { ModalCabinetSelectComponent } from "./modals/modal.cabinet.select.component";
import { ModalDraftConflictsComponent } from "./modals/modal.draft.conflicts.component";
import { ModalRegisterEventComponent } from "./modals/modal.register.event.component";
import { ModalEditRuleComponent } from "./modals/modal.edit.rule.component";
import { ModalCreateObjectComponent } from "./modals/modal.create.object.component";
import { ModalEditEventFormConfigComponent } from "./modals/modal.edit.event.form.config.component";
import { ModalEditEmailTemplateComponent } from "./modals/modal.edit.email.template.component";
import { ModalEmailTemplatePreviewComponent } from "./modals/modal.email.template.preview.component";
// import { ModalEditSetComponent } from "../master-definitions/modal.edit.set.component";
import { ModalEmailScenarioComponent } from "./modals/modal.email.scenario.component";
import { ModalEmailScenarioPreviewComponent } from "./modals/modal.email.scenario.preview.component";
import { ModalEditDocumentComponent } from "./modals/modal.edit.document.component";
import { ModalEditEmbeddedConfigComponent } from "./modals/modal.edit.embedded.config.component";
import { ModalEditTaxScheduleComponent } from "../s25-pricing/modal.edit.tax.schedule.component";
import { ModalInactiveTaskComponent } from "./modals/modal.inactive.task.component";
import { ModalCreateEditTaskComponent } from "../s25-task/modal.create.edit.task.component";
import { ModalEventFormMappingComponent } from "./modals/modal.event.form.mapping.component";
import { ModalOptimizerEffectiveDatingNgComponent } from "./modals/modal.optimizer-effectived-dating.component";
import { ModalEditBuildingComponent } from "./modals/modal.edit.building.component";
import { ModalEditMasqueradeComponent } from "./modals/modal.edit.masquerade.component";
import { ModalSwitchRoleComponent } from "./modals/modal.switch.roles.component";
import { ModalEventFormPreviewComponent } from "./modals/modal.event.form.preview.component";
import { ModalAlertComponent } from "./modals/modal.alert.component";

export type ModalName = keyof typeof ModalMap.typedMap;

export class ModalMap {
    public static typedMap = {
        dialog: { templateUrl: "s25-modal-dialog.tmpl", title: "Dialog", size: "md", controller: ModalDialogComponent },
        "recurring-reservation": {
            templateUrl: "s25-modal-recurring-reservation.tmpl",
            title: "Dialog",
            size: "lg",
            controller: "s25ModalRecurringReservationController",
            asDialog: true,
        },
        message: {
            templateUrl: "s25-modal-dialog.tmpl",
            title: "Message",
            size: "lg",
            controller: "s25ModalDialogController",
        },
        event: {
            templateUrl: "s25-modal-calendar-day-event.tmpl",
            title: "Event List",
            size: "sm",
            controller: "s25ModalEventController",
        },
        "save-search": {
            templateUrl: "s25-modal-save-search.tmpl",
            title: "Save Search",
            size: "lg",
            controller: "s25ModalSaveSearchController",
            asDialog: true,
        },
        "email-settings": {
            templateUrl: "s25-modal-email-settings.tmpl",
            title: "Email Settings",
            size: "lg",
            controller: "s25ModalEmailSettingsController",
        },
        alert: {
            title: "Alert",
            size: "lg",
            asDialog: true,
            controller: ModalAlertComponent,
        },

        //{itemId: itemId, feedType: feedType, compsubject: compsubject} (optionally searchQuery)
        publisher: {
            templateUrl: "s25-modal-publisher.tmpl",
            title: "Send Events to 25Live Publisher",
            size: "lg",
            controller: "s25ModalPublisherController",
            asDialog: true,
        },

        "share-search": {
            templateUrl: "s25-modal-share-search.tmpl",
            title: "Share Search",
            size: "lg",
            controller: "s25ModalShareSearchController",
            asDialog: true,
        },
        "advanced-search": {
            templateUrl: "s25-modal-advanced-search.tmpl",
            title: "Advanced Search",
            size: "lg",
            controller: "s25ModalAdvancedSearchController",
        },

        //requires data: {objId: eventId, type: clone, edit}
        "copy-edit-event": {
            templateUrl: "s25-modal-copy-edit-event.tmpl",
            size: "lg",
            controller: "s25ModalCopyEditEventController",
            asDialog: true,
        },

        copyright: {
            templateUrl: "s25-modal-copyright.tmpl",
            size: "lg",
            title: "Copyright",
            defaultData: { currentDate: new Date() },
        },
        "icon-legend": {
            templateUrl: "s25-modal-icon-legend.tmpl",
            size: "lg",
            title: "Icon Legend",
            controller: "s25ModalIconLegendController",
        },
        "recommended-browsers": {
            templateUrl: "s25-modal-recommended-browsers.tmpl",
            size: "lg",
            title: "Browser Recommendations",
            controller: ModalRecommendedBrowsersComponent,
            defaultData: { iconClass: "ng25Live" },
        },

        //requires data: { eventId: eventId, type: "create" } or { todoId: todoId, type: "edit" } or { taskType, taskId, type: "edit", eventId, taskActionsCell: {see taskAction} }
        "create-edit-task": {
            size: "lg",
            controller: ModalCreateEditTaskComponent,
            asDialog: true,
            defaultData: { iconClass: "ngTodo" },
        },

        "create-object": {
            size: "md",
            controller: ModalCreateObjectComponent,
            asDialog: true,
        },

        //requires data: {eventId: eventId, taskTypeId: }
        "change-request": {
            templateUrl: "s25-modal-change-request.tmpl",
            size: "lg",
            controller: "s25ModalChangeRequestController",
            asDialog: true,
            defaultData: { iconClass: "ngTodo" },
        },

        //requires data: {itemId: itemId}
        "register-event": {
            size: "lg",
            title: "Register",
            controller: ModalRegisterEventComponent,
            defaultData: { iconClass: "ngTodo" },
            asDialog: true,
        },

        "contact-info": {
            templateUrl: "s25-modal-contact-info.tmpl",
            size: "md",
            controller: "s25ModalContactInfoController",
            defaultData: { iconClass: "ngContact" },
            asDialog: true,
        },
        "date-restriction": {
            templateUrl: "s25-modal-date-restriction.tmpl",
            size: "md",
            controller: "s25ModalDateRestrictionController",
            defaultData: { iconClass: "ngEditIcon" },
            asDialog: true,
        },

        //migration note: overdue tasks intercepted in controller.xml instead of modals.xml
        //optional: include numTasks int, else it is fetched
        "overdue-tasks": {
            templateUrl: "s25-modal-overdue-tasks.tmpl",
            size: "md",
            controller: "s25ModalOverdueTasksController",
            defaultData: { iconClass: "ngTodo" },
        },

        //{eventId, orgId}
        invoice: {
            templateUrl: "s25-modal-invoice.tmpl",
            size: "md",
            controller: "s25ModalInvoiceController",
            defaultData: { iconClass: "ngInvoice" },
            asDialog: true,
        },

        //{eventId, orgId, isSetMode}
        "invoice-freshbooks": {
            templateUrl: "s25-modal-invoice-freshbooks.tmpl",
            size: "md",
            controller: "s25ModalInvoiceFreshbooksController",
            defaultData: { iconClass: "ngInvoice" },
            asDialog: true,
        },

        "optimizer-effective-dating": {
            templateUrl: "s25-modal-optimizer-effective-dating.tmpl",
            size: "lg",
            title: "Optimizer Effective Dating",
            asDialog: true,
            controller: "s25ModalOptimizerEffectiveDatingController",
        },

        login: {
            templateUrl: "s25-modal-login.tmpl",
            size: "md",
            controller: "s25ModalLoginController",
            asDialog: true,
            defaultData: { iconClass: "ngContact" },
            title: "Sign In",
        },

        //requires data: {event response json with parents list in it from saving event (only if response code is EV_I_MULTPAR)}
        "cabinet-select": {
            size: "sm",
            controller: ModalCabinetSelectComponent,
            title: "Choose Event Heading",
            asDialog: true,
            defaultData: { iconClass: "ngEventIcon" },
        },

        "draft-conflicts": {
            size: "md",
            controller: ModalDraftConflictsComponent,
            title: "Draft Conflicts",
            asDialog: true,
            defaultData: { iconClass: "ngDraft" },
        },

        "event-conflicts": {
            templateUrl: "s25-modal-event-conflicts.tmpl",
            size: "md",
            controller: "s25ModalEventConflictsController",
            title: "Conflicts",
            asDialog: true,
        },

        "welcome-message": {
            templateUrl: "s25-modal-welcome-message.tmpl",
            size: "md",
            controller: "s25ModalWelcomeMessageController",
            title: "\xa0",
            asDialog: false,
        },

        "viewer-message": {
            templateUrl: "s25-modal-viewer-message.tmpl",
            size: "md",
            controller: "s25ModalViewerMessageController",
            title: "\xa0",
            asDialog: false,
        },

        "s25ql-help": {
            templateUrl: "s25-modal-s25ql-help.tmpl",
            size: "md",
            controller: "s25ModalS25qlHelpController",
            title: "SeriesQL Help",
            asDialog: false,
        },

        "event-summary": {
            templateUrl: "s25-modal-event-summary.tmpl",
            size: "lg",
            controller: "s25ModalEventSummary",
            title: "Event Summary",
            asDialog: false,
        },

        //requires data: {}
        "image-upload": {
            size: "lg",
            title: "Image Upload",
            controller: ModalImageUploadComponent,
            defaultData: { iconClass: "ng25Live" },
            asDialog: true,
        },

        "event-form-date-occurrences": {
            templateUrl: "s25-modal-event-form-date-occurrences.tmpl",
            size: "lg",
            controller: "s25ModalEventFormDateOccurrences",
            title: "Date Occurrences",
            asDialog: true,
        },

        "event-form-object-occurrences-table": {
            templateUrl: "s25-modal-event-form-object-occurrences-table.tmpl",
            size: "lg",
            controller: "s25ModalEventFormObjectOccurrencesTable",
            title: "Occurrences",
            asDialog: true,
        },

        "event-form-date-pattern-picker": {
            templateUrl: "s25-modal-event-form-date-pattern-picker.tmpl",
            size: "lg",
            controller: "s25ModalEventFormDatePatternPicker",
            title: "Pattern Picker",
            asDialog: true,
        },

        "event-form-mapping": {
            // templateUrl: "s25-modal-event-form-mapping.tmpl",
            size: "md",
            controller: ModalEventFormMappingComponent,
            title: "Occurrence Mapping",
            asDialog: true,
        },

        "organization-creation": {
            templateUrl: "s25-modal-organization-creation.tmpl",
            size: "md",
            controller: "s25ModalOrganizationCreationController",
            title: "Organization Creation",
            asDialog: true,
        },

        "event-form-preview": {
            size: "md",
            title: "Preview",
            controller: ModalEventFormPreviewComponent,
            asDialog: true,
        },

        "discrete-options": {
            templateUrl: "s25-modal-discrete-options.tmpl",
            size: "md",
            title: "Discrete Options",
            asDialog: true,
            saveEvent: "save-discrete-options",
        },

        "notification-policy": {
            templateUrl: "s25-modal-notification-policy.tmpl",
            size: "md",
            title: "Notification Policy",
            asDialog: true,
            saveEvent: "s25-ng-notification-policy",
            deleteEvent: "s25-ng-notification-policy",
        },

        express: { templateUrl: "s25-modal-express.tmpl", size: "md", title: "Express Scheduling", asDialog: true },

        "freshbooks-account": {
            templateUrl: "s25-modal-freshbooks-account.tmpl",
            size: "md",
            title: "Freshbooks Account",
            asDialog: true,
        },

        "sample-report": {
            templateUrl: "s25-modal-sample-report.tmpl",
            size: "lg",
            title: "Sample Report",
            asDialog: true,
            ariaLabelledBy: "sampleReport",
        },

        // requires data: {itemId: number, taskComment: string, hideButton?: boolean, assigneeId?: number}
        "cancel-request": {
            size: "md",
            title: "Event Cancellation Request",
            controller: ModalCancelRequestComponent,
            defaultData: { iconClass: "ng25Live" },
        },

        "edit-rule": { size: "xl", title: "Edit Rule", asDialog: true, controller: ModalEditRuleComponent },

        "edit-event-form-config": {
            size: "xl",
            title: "Edit Event Form Config",
            asDialog: true,
            controller: ModalEditEventFormConfigComponent,
        },

        "edit-email-template": {
            size: "xl",
            title: "Edit Email Template",
            asDialog: true,
            controller: ModalEditEmailTemplateComponent,
        },

        "email-template-preview": {
            size: "lg",
            title: "Preview Template",
            asDialog: true,
            controller: ModalEmailTemplatePreviewComponent,
        },

        "todo-email-template-preview": {
            size: "lg",
            title: "Preview To Do",
            asDialog: true,
            controller: ModalEmailTemplatePreviewComponent,
        },
        // "edit-set": {
        //     size: "xl",
        //     title: "Edit Set",
        //     asDialog: true,
        //     controller: ModalEditSetComponent,
        // },
        "edit-email-scenario": {
            size: "xl",
            title: "Edit Email Scenario",
            asDialog: true,
            controller: ModalEmailScenarioComponent,
        },

        "email-scenario-preview": {
            size: "lg",
            title: "Preview Scenario",
            asDialog: true,
            controller: ModalEmailScenarioPreviewComponent,
        },

        "edit-document": {
            size: "lg",
            title: "Preview Scenario",
            asDialog: true,
            controller: ModalEditDocumentComponent,
        },

        "edit-embedded-config": {
            size: "xl",
            title: "Edit Embed Config",
            asDialog: true,
            controller: ModalEditEmbeddedConfigComponent,
        },

        "edit-tax-schedule": {
            size: "xl",
            title: "Edit Tax Schedule",
            asDialog: true,
            controller: ModalEditTaxScheduleComponent,
        },

        "inactive-task": {
            size: "lg",
            controller: ModalInactiveTaskComponent,
            asDialog: true,
        },

        "optimizer-effective-dating-ng": {
            size: "xl",
            title: "Optimizer Effective Dating",
            asDialog: true,
            controller: ModalOptimizerEffectiveDatingNgComponent,
        },

        "edit-building": {
            size: "xl",
            title: "Edit Building",
            asDialog: true,
            controller: ModalEditBuildingComponent,
        },

        "edit-masquerade": {
            size: "xl",
            title: "Edit Masquerade Mapping",
            asDialog: true,
            controller: ModalEditMasqueradeComponent,
        },

        "switch-roles": {
            size: "xl",
            title: "Swith Roles",
            asDialog: true,
            controller: ModalSwitchRoleComponent,
        },
    };

    public static map = ModalMap.typedMap as {
        [key: string]: {
            size?: "sm" | "md" | "lg";
            title?: string;
            iconClass?: string;
            templateUrl?: string;
            asDialog?: boolean;
            controller?: any;
            defaultData?: any;
            saveEvent?: string;
            deleteEvent?: string;
        };
    };
}
